import { useFormik } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import {
  setSuccess,
  useFormData,
  useErrors,
  useIsLoading,
  useSuccess,
} from "../../../redux/reducers/ExpLogReducer";
import { fetchStaticData } from "../../../redux/sagas/experienceLog/formDataSaga";
import { submitExpLog } from "../../../redux/sagas/experienceLog/submitFormSaga";
import { useDispatchEffect } from "../../../utils/hooks";
import { validateLogHourForm } from "../../../utils/validation";
import "./editAndResend.css";
import { LABELS, LOG_DATA } from "../../../utils/CONSTANTS";
import LocalStorage from "../../../services/LocalStorage";
import { useNavigate } from "react-router-dom";
import { editExpLog } from "../../../redux/sagas/experienceLog/editFormSaga";
import MultipleSelect from "../../../components/MultipleSelect";

const KEYS = Object.keys(LABELS);

const EditAndResend = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const showSidebar = useShowSidebar();
  const data = useFormData();
  const isLoading = useIsLoading();

  const [flagItems, setFlagItems] = useState(new Map());

  let {
    expTypes,
    setting,
    supervisors,
    obsevations,
    methods,
    contacts,
    tasks,
  } = data;
  // tasks.length && tasks.sort((a,b) => (a.id - b.id));
  const taskNames = tasks?.map((t) => t.name.split(" ")[0]);
  const taskIds = tasks?.map((t) => t.id);

  useDispatchEffect(fetchStaticData, null, expTypes?.length == 0);

  const log = LocalStorage.getData(LOG_DATA);

  const initialValues = {
    expereince_type: log?.expereince_type?.id || 1,
    setting: log?.setting?.id || 1,
    method_of_supervision: log?.method_of_supervision?.id || 1,
    client_observation: log?.client_observation?.id || 1,
    supervision_contact: log?.supervision_contact?.id || 1,
    task: log.task?.map((t) => t.name.split(" ")[0]) || [],
    supervisor: log?.supervisor?.id || 1,
    date_of_experience:
      log?.date_of_experience || moment().format("MM/DD/YYYY"),
    // time_of_experience: log?.time_of_experience || "",
    time_of_expereince_start: log?.time_of_expereince_start || "",
    time_of_expereince_end: log?.time_of_expereince_end || "",
    unrestricted_hours: log?.unrestricted_hours || "",
    restricted_hours: log?.restricted_hours || "",
    experience_hours: log?.experience_hours || "",
    individual_or_group: log?.individual_or_group || "Individual",
    supervision_start_time: log?.supervision_start_time || "",
    supervision_end_time: log?.supervision_end_time || "",
    supervised_hours: log?.supervised_hours || "",
    independant_hours: log?.independant_hours || "",
    experience_note: log?.experience_note || "",
  };

  const onSubmit = (values, onSubmitProps) => {
    const selected = values.task;
    const newValues = {
      ...values,
      task: selected.map((t) => taskIds[taskNames.indexOf(t)]),
    };
    if (values.individual_or_group == "No supervision") {
      newValues["method_of_supervision"] = null;
      newValues["supervision_start_time"] = null;
      newValues["supervision_end_time"] = null;
      newValues["supervised_hours"] = null;
      newValues["independant_hours"] = null;
      newValues["client_observation"] = null;
      newValues["supervision_contact"] = null;
    }
    console.log({
      id: log?.id,
      log: newValues,
    });
    dispatch(
      editExpLog({
        id: log?.id,
        log: newValues,
      })
    );
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate: validateLogHourForm,
  });

  const logCreateSuccess = useSuccess();
  const logCreateErrors = useErrors();

  useEffect(() => {
    if (logCreateSuccess) {
      toast.success("Successfully submitted");
      dispatch(setSuccess(false));
      navigate(-1);
    }
  }, [logCreateSuccess]);

  useEffect(() => {
    if (logCreateErrors && Array.isArray(logCreateErrors))
      logCreateErrors.forEach((err) => {
        toast.error(err);
      });
  }, [logCreateErrors]);

  useEffect(() => {
    if (log?.data) {
      const flaggedItems = new Map();
      Object.keys(log?.data).forEach((key) => {
        if (Object.keys(LABELS).includes(key)) flaggedItems.set(key, true);
      });
      setFlagItems(flaggedItems);
    }
  }, []);

  // console.log('values: ',formik.values);

  if (isLoading) return <LoadingSpinner style={{ width: "100%" }} />;

  return (
    <div className="section has-light-background">
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>

          <p className="headingMain">
            <a className="backlink" onClick={(_) => navigate(-1)}>
              <IoIosArrowBack size={24} />
            </a>
            Edit and Resend
          </p>

          <form onSubmit={formik.handleSubmit}>
            <div className="column mt30 is-two-fifths logForm">
              <label className="label">
                Experience Type{" "}
                {flagItems.get(KEYS[0]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <select
                name="expereince_type"
                className="select dropdownCustom mt is-normal"
                value={formik.values.expereince_type}
                onChange={formik.handleChange}
              >
                {expTypes.map((item) => (
                  <option value={Number(item.id)} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="Register__error">
                {(formik.touched.expereince_type &&
                  formik.errors.expereince_type) ||
                  ""}
              </span>

              <label className="label">
                Setting{" "}
                {flagItems.get(KEYS[1]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>

              <select
                name="setting"
                className="select dropdownCustom mt is-normal"
                value={formik.values.setting}
                onChange={formik.handleChange}
              >
                {setting.map((item) => (
                  <option value={Number(item.id)} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="Register__error">
                {(formik.touched.setting && formik.errors.setting) || ""}
              </span>

              <label className="label">
                Supervisor{" "}
                {flagItems.get(KEYS[2]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <select
                name="supervisor"
                className="select dropdownCustom mt is-normal"
                value={formik.values.supervisor}
                onChange={formik.handleChange}
              >
                {supervisors.map((item) => (
                  <option value={Number(item.id)} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
              <span className="Register__error">
                {(formik.touched.supervisor && formik.errors.supervisor) || ""}
              </span>

              <label className="label">
                Date of Experience{" "}
                {flagItems.get(KEYS[3]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              {/* <div style={{ width: '100%'}}>
              <DatePicker
                name='date_of_experience'
                 placeholderText='mm/dd/yyyy'
                 selected={new Date(formik.values.date_of_experience)}
                onChange={(date, e) => formik.handleChange(e)} 
                className='date-picker'
                />
              </div> */}
              <input
                className="input is-small"
                type="date"
                placeholder=" "
                name="date_of_experience"
                value={formik.values.date_of_experience}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.date_of_experience &&
                  formik.errors.date_of_experience) ||
                  ""}
              </span>

              {/* <label className="label">
                Time of Experience{" "}
                {flagItems.get(KEYS[4]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="time"
                placeholder=" "
                name="time_of_experience"
                value={formik.values.time_of_experience}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.time_of_experience &&
                  formik.errors.time_of_experience) ||
                  ""}
              </span> */}
              <label className="label">
                Time of Experience Start{" "}
                {flagItems.get(KEYS[4]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="time"
                placeholder=" "
                name="time_of_expereince_start"
                value={formik.values.time_of_expereince_start}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.time_of_expereince_start &&
                  formik.errors.time_of_expereince_start) ||
                  ""}
              </span>
              <label className="label">
                Time of Experience End{" "}
                {flagItems.get(KEYS[5]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="time"
                placeholder=" "
                name="time_of_expereince_end"
                value={formik.values.time_of_expereince_end}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.time_of_expereince_end &&
                  formik.errors.time_of_expereince_end) ||
                  ""}
              </span>

              <label className="label">
                Unrestricted Hours{" "}
                {flagItems.get(KEYS[6]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="number"
                placeholder="Type here ..."
                name="unrestricted_hours"
                value={formik.values.unrestricted_hours}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.unrestricted_hours &&
                  formik.errors.unrestricted_hours) ||
                  ""}
              </span>

              <label className="label">
                Restricted Hours{" "}
                {flagItems.get(KEYS[7]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="number"
                placeholder="Type here ..."
                name="restricted_hours"
                value={formik.values.restricted_hours}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.restricted_hours &&
                  formik.errors.restricted_hours) ||
                  ""}
              </span>

              <label className="label">
                Experience Hours{" "}
                {flagItems.get(KEYS[8]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <input
                className="input is-small"
                type="number"
                placeholder="Type here ..."
                name="experience_hours"
                value={formik.values.experience_hours}
                onChange={formik.handleChange}
              />
              <span className="Register__error">
                {(formik.touched.experience_hours &&
                  formik.errors.experience_hours) ||
                  ""}
              </span>

              <label className="label">
                Individual or group supervision?{" "}
                {flagItems.get(KEYS[9]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>

              <select
                name="individual_or_group"
                className="select dropdownCustom mt is-normal"
                value={formik.values.individual_or_group}
                onChange={formik.handleChange}
              >
                <option value="Individual">Individual</option>
                <option value="Group">Group</option>
                <option value="No supervision">No supervision</option>
              </select>
              <span className="Register__error">
                {(formik.touched.individual_or_group &&
                  formik.errors.individual_or_group) ||
                  ""}
              </span>

              {formik.values.individual_or_group === "No supervision" ? null : (
                <>
                  <label className="label">
                    Method of Supervision{" "}
                    {flagItems.get(KEYS[10]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="text"
                    placeholder="Type here ..."
                    name="method_of_supervision"
                    value={formik.values.method_of_supervision}
                    onChange={formik.handleChange}
                  />
                  {/* <select
                    name="method_of_supervision"
                    className="select dropdownCustom mt is-normal"
                    value={formik.values.method_of_supervision}
                    onChange={formik.handleChange}
                  >
                    {methods.map((item) => (
                      <option value={Number(item.id)} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select> */}
                  <span className="Register__error">
                    {(formik.touched.method_of_supervision &&
                      formik.errors.method_of_supervision) ||
                      ""}
                  </span>

                  <label className="label">
                    Supervision Start Time{" "}
                    {flagItems.get(KEYS[11]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="time"
                    placeholder=" "
                    name="supervision_start_time"
                    value={formik.values.supervision_start_time}
                    onChange={formik.handleChange}
                  />
                  <span className="Register__error">
                    {(formik.touched.supervision_start_time &&
                      formik.errors.supervision_start_time) ||
                      ""}
                  </span>

                  <label className="label">
                    Supervision End Time{" "}
                    {flagItems.get(KEYS[12]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="time"
                    placeholder=" "
                    name="supervision_end_time"
                    value={formik.values.supervision_end_time}
                    onChange={formik.handleChange}
                  />
                  <span className="Register__error">
                    {(formik.touched.supervision_end_time &&
                      formik.errors.supervision_end_time) ||
                      ""}
                  </span>

                  <label className="label">
                    Supervised Hours{" "}
                    {flagItems.get(KEYS[13]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="number"
                    placeholder="Type here ..."
                    name="supervised_hours"
                    value={formik.values.supervised_hours}
                    onChange={formik.handleChange}
                  />
                  <span className="Register__error">
                    {(formik.touched.supervised_hours &&
                      formik.errors.supervised_hours) ||
                      ""}
                  </span>

                  <label className="label">
                    Independent Hours{" "}
                    {flagItems.get(KEYS[14]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="number"
                    placeholder="Type here ..."
                    name="independant_hours"
                    value={formik.values.independant_hours}
                    onChange={formik.handleChange}
                  />
                  <span className="Register__error">
                    {(formik.touched.independant_hours &&
                      formik.errors.independant_hours) ||
                      ""}
                  </span>

                  <label className="label">
                    Client Observation?{" "}
                    {flagItems.get(KEYS[15]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <input
                    className="input is-small"
                    type="text"
                    placeholder="Type here ..."
                    name="client_observation"
                    value={formik.values.client_observation}
                    onChange={formik.handleChange}
                  />
                  {/* <select
                name="client_observation"
                className="select dropdownCustom mt is-normal"
                value={formik.values.client_observation}
                onChange={formik.handleChange}
              >
                {obsevations.map((item) => (
                  <option value={Number(item.id)} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select> */}
                  <span className="Register__error">
                    {(formik.touched.client_observation &&
                      formik.errors.client_observation) ||
                      ""}
                  </span>

                  <label className="label">
                    Supervision contacts{" "}
                    {flagItems.get(KEYS[16]) ? (
                      <span className="flagged">(Flagged)</span>
                    ) : (
                      ""
                    )}
                  </label>
                  <select
                    name="supervision_contact"
                    className="select dropdownCustom mt is-normal"
                    value={formik.values.supervision_contact}
                    onChange={formik.handleChange}
                  >
                    {contacts.map((item) => (
                      <option value={Number(item.id)} key={item.id}>
                        {item.contact}
                      </option>
                    ))}
                  </select>
                  <span className="Register__error">
                    {(formik.touched.supervision_contact &&
                      formik.errors.supervision_contact) ||
                      ""}
                  </span>
                </>
              )}

              <label className="label">
                Task list items documented{" "}
                {flagItems.get(KEYS[17]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              {/* <select
                name="task"
                className="select dropdownCustom mt is-normal"
                value={formik.values.task}
                onChange={formik.handleChange}
              >
                {tasks.map((item) => (
                  <option value={Number(item.id)} key={item.id}>
                    {item.name.split(" ")[0]}
                  </option>
                ))}
              </select> */}
              <MultipleSelect
                name="task"
                value={formik.values.task}
                handleChange={formik.handleChange}
                items={taskNames}
              />

              <span className="Register__error">
                {(formik.touched.task && formik.errors.task) || ""}
              </span>

              <label className="label">
                Experience Notes{" "}
                {flagItems.get(KEYS[18]) ? (
                  <span className="flagged">(Flagged)</span>
                ) : (
                  ""
                )}
              </label>
              <textarea
                className="textarea"
                placeholder="Type here..."
                name="experience_note"
                value={formik.values.experience_note}
                onChange={formik.handleChange}
              ></textarea>
              <span className="Register__error">
                {(formik.touched.experience_note &&
                  formik.errors.experience_note) ||
                  ""}
              </span>

              <button
                className="button is-two-fifths is-large is-rounded dashBtn mt30"
                type="submit"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditAndResend;
