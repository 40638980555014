import moment from "moment";

export function formatDate(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() is 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export function getMonthDateRange(date=new Date()) {
    if(!date){
        return new Date()
    }
    // let day = moment('2014-07-28', 'YYYY/MM/DD').date();
    const  month = 1 + moment(date, 'YYYY/MM/DD').month();
    const year = moment(date, 'YYYY/MM/DD').year()

    // month in moment is 0 based, so 9 is actually october, subtract 1 to compensate
    // array is 'year', 'month', 'day', etc
    var startDate = moment([year, month - 1]);

    // Clone the value before .endOf()
    var endDate = moment(startDate).endOf('month');

    // just for demonstration:
    // console.log(startDate.toDate());
    // console.log(endDate.toDate());

    // make sure to call toDate() for plain JavaScript date type
    return { start:  moment(startDate.toDate()).format("YYYY-MM-DD"), end: moment(endDate.toDate()).format("YYYY-MM-DD") };
}