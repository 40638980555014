import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setUserProfile } from "../../reducers/ProfileReducer";
import { startFetching, removeTrainee, setError } from "../../reducers/TraineesReducer";

export const removeTraineeAction = createAction("trainee/removeTraineeAction");

function* postData({ payload }) {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.removeTrainee, payload);

    if(res.data.msg === 'Successfully User removed'){
      toast.success(res.data.msg);
      let res2 = yield call(ApiService.getProfile);
      if(res2.data.results)
        yield put(setUserProfile(res2.data.results[0]));
    }
  } catch (error) {
    console.log({ error });
    toast.error(error)
    yield put(setError(error))
  }
}

export function* deleteSaga() {
  yield takeLatest(removeTraineeAction, postData);
}

