import * as React from "react";
import "./styles.css";
import moment from "moment";
import { useLocation } from "react-router-dom";

const DateButton = ({ date, active, onClick, selected, workday, statusDates,type }) => {

  const location = useLocation();
  const hasPendingLog = () => {
      return statusDates?.some(e => (
        e.date_of_experience === moment(date).format('YYYY-MM-DD')
        && e.status === 'pending')
      )
  }
  
  const getStatusDots = () => {
    const dateFormatted = moment(date).format('YYYY-MM-DD');
    const statuses = statusDates
      .filter(e => e.date_of_experience === dateFormatted)
      .map(e => e.status);

    console.log('statuses',statuses)

    const uniqueStatuses = [...new Set(statuses)];
    return uniqueStatuses.map((status, index) => {
      let color;
      switch (status) {
        case 'approved':
          color = '#1fc922'; // Green
          break;
        case 'pending':
          color = '#e31d1d'; // Red
          break;
        case 'flagged':
          color = '#1fc922'; // Green
          break;
        default:
          color = '#FFF'; // Default color if status is not recognized
      }

      return (
        <div
          key={index}
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "8px",
            backgroundColor: color,
            marginTop: "2px",
            marginLeft: index > 0 ? "2px" : "0"
          }}
        />
      );
    });
  };


  
  return (
    <button
      className={`sdp--date-btn sdp--text ${!active ? "sdp--text__inactive" : ""}`}
      onClick={() => onClick(date)}
      tabIndex={active ? 0 : -1}
      aria-label={`${selected ? "Currently selected" : "Select"} ${date.toLocaleDateString("en-US")}`}
      type="button"
    >
      <div className={`${selected ? "sdp--date-btn__selected" : "sdp--square-btn"}`}>
        {date.getDate()}
      </div>

      {active && (
        <div
          style={{
            width: "7px",
            height: "7px",
            borderRadius: "8px",
            backgroundColor: workday ? hasPendingLog() ? '#e31d1d' : '#1fc922' : "#FFF",
            marginTop: "2px",
          }}
        />
      )}
      {active && location.pathname==='/supervisor/signlogs' && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          {getStatusDots()}
        </div>
      )}
    </button>
  )
};

export default DateButton
