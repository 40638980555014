import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setSupervisors, setSuccess, startFetching } from "../../reducers/ProfileReducer";
import { toast } from "react-toastify";

export const fetchSupervisors = createAction("profile/fetchSupervisors");

function* fetchData() {
  try {
    let res = yield call(ApiService.getTraineeSupervisors);
    if(res.data)
      yield put(setSupervisors(res.data.results));
  } catch (error) {
    console.log({ error });
  }
}

export function* fetchSupervisorsSaga() {
  yield takeLatest(fetchSupervisors, fetchData);
}


export const submitTraineeSupervisor = createAction("profile/submitSupervisor");

function* submitSupervisor({ payload }) {
  const { form, refresh } = payload

  try {
    let res = yield call(ApiService.postTraineeSupervisor, form);
    if(res.data.id) {
      yield put(setSuccess(true))
      toast.success('Successfully submitted!');

      if (refresh) {
        refresh()
      }
    } else {
      for (const errField in res.data) {
        const errors = res.data[errField];
        if(Array.isArray(errors) && errors.length)
          errors.forEach(err => {
            toast.error(err);
          })
          if(typeof errors === "string") {
            toast.error(errors);
          }
        console.log('errors: ', errors)
              }
    }
  } catch (error) {
    console.error('postSupervisors: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  }
}

export function* submitSupervisorSaga() {
  yield takeLatest(submitTraineeSupervisor, submitSupervisor);
}


export const updateTraineeSupervisor = createAction("profile/updateSupervisor");

function* updateSupervisor({ payload }) {
  const { id, form, refresh } = payload

  try {
    let res = yield call(ApiService.updateTraineeSupervisor, id, form);
    if(res.data.id) {
      yield put(setSuccess(true))
      toast.success('Successfully updated!');
      if(refresh) {
        refresh()
      }
    } else {
      for (const errField in res.data) {
        const errors = res.data[errField];
        if(Array.isArray(errors) && errors.length)
          errors.forEach(err => {
            toast.error(err);
          })
          if(typeof errors === "string") {
            toast.error(errors);
          }
        console.log('errors: ', errors)
      }
    }
  } catch (error) {
    console.error('updateSupervisors: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  }
}

export function* updateTraineeSupervisorSaga() {
  yield takeLatest(updateTraineeSupervisor, updateSupervisor);
}


export const deleteTraineeSupervisor = createAction("profile/deleteSupervisor");

function* deleteSupervisor({ payload }) {
  const { id, refresh } = payload

  try {
    let res = yield call(ApiService.deleteTraineeSupervisor, id);
    if(res.status === 204) {
      yield put(setSuccess(true))
      toast.success('Successfully deleted!');
      refresh()
    } else {
      for (const errField in res.data) {
        const errors = res.data[errField];
        if(Array.isArray(errors) && errors.length)
          errors.forEach(err => {
            toast.error(err);
          })
        console.log('errors: ', errors)
        if(typeof errors === "string") {
          toast.error(errors);
        }
      }
    }
  } catch (error) {
    console.error('deleteSupervisors: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  }
}

export function* deleteTraineeSupervisorSaga() {
  yield takeLatest(deleteTraineeSupervisor, deleteSupervisor);
}
