import { createAction } from "@reduxjs/toolkit";
import { call, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { startFetching, setLogs, setErrors, setDates, setStatusDates, setAllDatesObj } from "../../reducers/ExpLogReducer";

export const fetchPendingDates = createAction("experienceLog/fetchPendingDates");

function* fetchData({payload}) {   
  yield put(startFetching(true))
 
  try {
    let res = yield call(ApiService.getPendingDates,payload.startDate,payload.endDate,payload.status);
    if(res.data.dates){
      const obj = res.data.dates.reduce((acc,el)=>{acc[el]=true 
        return  acc},{})
      yield put(setDates(res.data.dates));
      yield put(setAllDatesObj(obj));
      yield put(setStatusDates(res.data.dates_status));
    }
  } catch (error) {
    console.log({ error });
    yield put(setErrors(error))
  }
}

export function* fetchPendingDatesSaga() {
  yield takeLatest(fetchPendingDates, fetchData);
}

