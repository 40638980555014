import logo from "../../assets/title.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  useParams,
  useNavigate,
  Navigate,
  useLocation,
} from "react-router-dom";
import { useFormik } from "formik";
import "./login.css";
import {
  setSuccess,
  useAuhToken,
  useIsLoading,
  useIsSupervisor,
  useIsTrialExpired,
  useIsSubscriptionExpired,
  useUser,
  useResetMode,
  setResetMode,
} from "../../redux/reducers/AuthReducer";
import { signInAction, signOutAction } from "../../redux/sagas/auth/AuthSagas";
import SubscriptionDialog from "../../components/SubscriptionDialog";
import { toast } from "react-toastify";
import LoadingButton from "../../components/LoadingButton";
import { put } from "redux-saga/effects";
import LocalStorage from "../../services/LocalStorage";
import GoogleIcon from '@mui/icons-material/Google';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authUser = useUser();
  const isSupervisor = useIsSupervisor();
  const isTrialExpired = useIsTrialExpired();
  const isSubscriptionExpired = useIsSubscriptionExpired();
  const isLoading = useIsLoading();
  const authToken = useAuhToken();
  const resetMode = useResetMode();
  const { role } = useParams();

  useEffect(() => {
    if (authUser && resetMode) {
      formik?.resetForm();
      navigate(`/verify-email`);
      put(setResetMode(false))
    }
  }, [authUser, resetMode]);

  const [showDialog, setShowDialog] = useState(false);
  const [subscriptionMessage, setSubscriptionMessage] = useState('Looks like your 7 day free trial has expired.')

  useEffect(() => {
    if (authToken && isSupervisor !== null) {
      if (role === "supervisor") {
        if (isSupervisor === false) {
          toast.error("Invalid supervisor's credentials");
          dispatch(signOutAction());
        } else {
          toast.success("Successfully logged in", { autoClose: 3000 });
          navigate("/supervisor/signlogs", { replace: true });
        }
      } else if (role === "student") {
        if (isSupervisor === true) {
          toast.error("Invalid student's credentials");
          dispatch(signOutAction());
        } else {
          if (isTrialExpired) {
            setSubscriptionMessage('Looks like your 7 day free trial has expired.')
            setShowDialog(true);
          } else if (isSubscriptionExpired) {
            setSubscriptionMessage('Looks like your subscription has expired.')
            setShowDialog(true);
          } else {
            setShowDialog(false)
            toast.success("Successfully logged in", { autoClose: 3000 });
            LocalStorage.storeData("loginPopup", true);
            navigate("/student/logHour", { replace: true });
          }
        }
      }
    }
  }, [authToken, isSupervisor, isTrialExpired, isSubscriptionExpired]);

  const validate = (values) => {
    let errors = {};
    if (!values.name) {
      errors.name = "⋆Required";
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.name)) {
      errors.name = "Invalid email format";
    }
    if (!values.password) {
      errors.password = "⋆Required";
    } else if (values.password.length < 8) {
      errors.password = "Password must be 8 characters long";
    }
    return errors;
  };

  const initialValues = {
    name: authUser?.email || "",
    password: "",
  };

  const onSubmit = (values, onSubmitProps) => {
    const payload = {
      username: values.name,
      password: values.password,
    };
    dispatch(signInAction(payload));
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  const onSignup = () => {
    dispatch(setSuccess(false));
    navigate(`/register/${role}`);
  };

  const signInWithGoogle = () => {
    const clientId =
      "890000669998-80116f3gvb3ia1ajnvqntua098eoslrm.apps.googleusercontent.com";
    const callbackUri = process.env.REACT_APP_SSO_CALLBACK;
    localStorage.setItem("signInWithGoogleRole", role);
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=${callbackUri}&prompt=consent&response_type=code&client_id=${clientId}&scope=openid%20email%20profile&access_type=offline`;
  };

  return (
    <section className="section has-background">
      <div>
        <div className="columns">
          <div className="column is-one-third">
            <figure className="Logo">
              <img src={logo} alt="Melton Hill Lake" />
            </figure>
          </div>
        </div>
        <div className="columns">
          <div className="column is-one-third">
            <p className="headingMain">
              Welcome back, {role === "student" ? "Trainee" : "Supervisor"}{" "}
            </p>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="columns form-layout InputSize">
            <div className="column is-one-third">
              <label className="label">Email</label>
              <input
                className="input has-input-background"
                type="email"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.name && formik.errors.name) || ""}
              </span>
            </div>
          </div>
          <div className="columns form-layout InputSize">
            <div className="column is-one-third">
              <label className="label">Password</label>
              <input
                className="input has-input-background"
                type="password"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <span className="Register__error">
                {(formik.touched.password && formik.errors.password) || ""}
              </span>
            </div>
          </div>
          <div className="columns form-layout">
            <div className="column is-one-third">
              <div className="row-flex">
                <label className="checkbox">
                  <input type="checkbox" style={{ marginRight: "5px" }} />
                  Remember me
                </label>
                <a
                  className="forgot-pass"
                  onClick={(_) => navigate("/forgot-password")}
                >
                  Forgot Password?
                </a>
              </div>
            </div>
          </div>
          <div className="columns form-layout mt60 ">
            <div className="column is-one-third">
              <LoadingButton
                className="button is-large is-rounded signin-button"
                disabled={!formik.isValid}
                loading={isLoading}
              >
                Sign In
              </LoadingButton>
              <button
                style={{
                  backgroundColor: "#4285F4",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  padding: "10px 20px",
                  cursor: "pointer",
                  boxShadow: "0 2px 4px 0 rgba(0,0,0,0.25)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  margin: "10px 0",
                  fontSize: 16
                }}
                onClick={() => signInWithGoogle()}
              >
                <GoogleIcon />
                <div style={{ marginLeft: 10 }}>Continue with Google</div>
              </button>
            </div>
          </div>
        </form>

        <div className="columns form-layout mt15">
          <div className="column is-one-third">
            <div className="bottom-text-div">
              <a className="log-text" onClick={onSignup}>
                Don’t have an account? <strong>Sign Up</strong>
              </a>
            </div>
          </div>
        </div>

        <SubscriptionDialog
          isVisible={showDialog}
          closeDialog={() => setShowDialog(false)}
          message={subscriptionMessage}
        />
        {/* <div className="columns is-centered">
          <div className="column">
            <a className="signup-text" onClick={_ => navigate(`/subscription`)}>
              next
            </a>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default Login;
