// Reducer that changes the state based on the action

import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

export const slice = createSlice({
  name: "experienceLog",
  initialState: {
    isLoading: false,
    formData: {
      expTypes: [],
      setting: [],
      supervisors: [],
      obsevations: [],
      methods: [],
      contacts: [],
      tasks: [],
      allDatesObj:{}
    },
    selectedDate: (new Date()).toISOString(),
    logs: [],
    currentLogById: {},
    flagged: [],
    pastFlagged: [],
    dates: null,
    statusDates: null,
    csvData: '',
    monthlyChecks: null,
    success: false,
    errors: null
  },
  reducers: {
    startFetching: (state, action) => {
      state.isLoading = true;
      state.errors = null
    },
    setSuccess: (state, action) => {
      state.success = action.payload;
      state.isLoading = false;
    },
    setErrors: (state, action) => {
      state.errors = action.payload;
      state.isLoading = false;
    },
    setFormData: (state, action) => {
      state.formData = action.payload;
      state.isLoading = false;
    },
    setSelectedDate: (state, action) => {
      state.selectedDate = action.payload;
    },
    setLogs: (state, action) => {
      state.logs = action.payload;
      state.isLoading = false;
    },
    setCurrentLogById: (state, action) => {
      state.currentLogById = action.payload;
      state.isLoading = false;
    },
    setFlagged: (state, action) => {
      state.flagged = action.payload;
      state.isLoading = false;
    },
    setPastFlagged: (state, action) => {
      state.pastFlagged = action.payload;
      state.isLoading = false;
    },
    setCsvData: (state, action) => {
      state.csvData = action.payload;
      state.isLoading = false;
    },
    setDates: (state, action) => {
      state.dates = action.payload;
      state.isLoading = false;
    },
    setStatusDates: (state, action) => {
      state.statusDates = action.payload;
      state.isLoading = false;
    },
    setAllDatesObj: (state, action) => {
      state.allDatesObj = action.payload
    },
    setMonthlyChecks: (state, action) => {
      state.monthlyChecks = action.payload;
      state.isLoading = false;
    },
  },
});

export const {
  startFetching,
  setSuccess,
  setErrors,
  setFormData,
  setSelectedDate,
  setLogs,
  setCurrentLogById,
  setFlagged,
  setPastFlagged,
  setCsvData,
  setDates,
  setStatusDates,
  setMonthlyChecks,
  setAllDatesObj
} = slice.actions;

export const useFormData = () => useSelector((state) => state.ExpLog.formData);
export const useSelectedDate = () => useSelector((state) => state.ExpLog.selectedDate);
export const useLogs = () => useSelector((state) => state.ExpLog.logs);
export const useCurrentLog = () => useSelector((state) => state.ExpLog.currentLogById);
export const useFlagged = () => useSelector((state) => state.ExpLog.flagged);
export const usePastFlagged = () => useSelector((state) => state.ExpLog.pastFlagged);
export const useCsvData = () => useSelector((state) => state.ExpLog.csvData);
export const useDates = () => useSelector((state) => state.ExpLog.dates);
export const useStatusDates = () => useSelector((state) => state.ExpLog.statusDates);
export const useMonthlyChecks = () => useSelector((state) => state.ExpLog.monthlyChecks);
export const useIsLoading = () => useSelector((state) => state.ExpLog.isLoading);
export const useSuccess = () => useSelector((state) => state.ExpLog.success);
export const useErrors = () => useSelector((state) => state.ExpLog.errors);
export const useAllDatesObj = ()=> useSelector((state)=>state.ExpLog.allDatesObj)

export default slice.reducer;
