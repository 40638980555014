import { createAction } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { call, all, put, takeLatest } from "redux-saga/effects";
import ApiService from "../../../services/ApiService";
import { setDocs, setError, setSuccess, startFetching } from "../../reducers/MonthlyDocReducer";

export const fetchDoc = createAction("monthlyDoc/fetchDoc");


function* fetchData() {
  yield put(startFetching(true))
  try {
    let res = yield call(ApiService.getMonthlyDocument);
    if(res.data.results) {
      yield put(setDocs(res.data.results))
    }
  } catch (error) {
    console.error('fetchDoc: ', error);
    yield put(setSuccess(false))
    toast.error(error);
  } 
}

export function* fetchDocSaga() {
  yield takeLatest(fetchDoc, fetchData);
}
