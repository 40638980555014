import React, { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { SidebarHeader, SidebarContent } from "react-pro-sidebar";
import logo from "../assets/title.png";
import bottomLogo from "../assets/bottomLogo.png";
import { BsPencilSquare, BsFillHandIndexFill, BsTable } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FaClipboardList, FaChartPie } from "react-icons/fa";
import { RiErrorWarningLine } from "react-icons/ri";
import { ImUpload3 } from "react-icons/im";
import {
  setShowSidebar,
  useShowSidebar,
} from "../redux/reducers/SidebarReducer";
import { useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./component.css";
import { useDispatchEffect } from "../utils/hooks";
import {
  fetchAccrued,
  fetchAccruedByDate,
} from "../redux/sagas/monthlyDocument/fetchAccruedSaga";
import { useAccruedData } from "../redux/reducers/MonthlyDocReducer";
import moment from "moment";
import LocalStorage from "../services/LocalStorage";
import { USER_DATA } from "../utils/CONSTANTS";
import { fetchTable } from "../redux/sagas/dasboard/fetchTableSaga";
import { usetableData } from "../redux/reducers/DashboardReducer";
import { findPercentage, findSum } from "../services/utiltities";
import { returnUpperBound } from "../utils/textUtil";

const routes = [
  { id: 1, path: "/logHour" },
  { id: 2, path: "/profile-screen" },
  { id: 3, path: "/dashboard-subscription" },
  { id: 4, path: "/monthly-document" },
  { id: 6, path: "/upload" },
  { id: 7, path: "/supervision-contact-agenda" },
];
export default function StudentSidebar() {
  const user = LocalStorage.getData(USER_DATA);
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  const location = useLocation();
  const accrued = useAccruedData();
  const path = location.pathname.replace("/student", "");
  const routeId = routes.find((route) => route.path === path)?.id;
  const [activeItem, setActiveItem] = useState(routeId);
  const [values, setValues] = useState();
  const today = new Date();
  const week = {
    from: moment(today, "YYYY-MM-DD").startOf("month").format("YYYY-MM-DD"),
    to: moment(today, "YYYY-MM-DD").endOf("month").format("YYYY-MM-DD"),
  };

  useDispatchEffect(fetchAccrued, null, true);
  useDispatchEffect(fetchAccruedByDate, { u_id: user.id, week }, week.from);


  const tableData = usetableData();
  const total_hours = findSum("total_hours", tableData);
  const total_super = findSum("supervised", tableData);
  const total_super_per = findPercentage(total_super, total_hours).toFixed(1);
  const total_unres = findSum("unrestricted", tableData);
  const total_res = findSum("restricted", tableData);
  const total_unres_per = findPercentage(
    total_unres,
    total_unres + total_res
  ).toFixed(1);

  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = accrued.total;

  useEffect(() => {
    setValues({
      accruedData: { total: accrued?.total, weekly: accrued?.weekly },
    });
  }, [accrued]);

  return (
    <div>
      <ProSidebar
        breakPoint="sm"
        toggled={showSidebar}
        onToggle={() => dispatch(setShowSidebar(!showSidebar))}
      >
        <SidebarHeader style={{ backgroundColor: "#F4DEEA" }}>
          <figure>
            <img src={logo} alt="Melton Hill Lake" />
            {/* <img className="bottomLogoNav" src={bottomLogo} alt="bottomLogo" /> */}
          </figure>
        </SidebarHeader>
        <SidebarContent style={{ backgroundColor: "#F4DEEA" }}>
          <Menu iconShape="square mt60">
            <MenuItem
              icon={<CgProfile />}
              active={activeItem === 2}
              onClick={() => setActiveItem(2)}
            >
              Profile
              <Link to="/student/profile-screen" />
            </MenuItem>
            <MenuItem
              icon={<FaChartPie />}
              active={activeItem === 0}
              onClick={() => setActiveItem(0)}
            >
              Dashboard
              <Link to="/student/dashboard" />
            </MenuItem>
            <MenuItem
              icon={<BsPencilSquare />}
              active={activeItem === 1}
              onClick={() => setActiveItem(1)}
            >
              Log experience hours
              <Link to="/student/logHour" />
            </MenuItem>
            {/* <MenuItem
              icon={<BsFillHandIndexFill />}
              active={activeItem === 3}
              onClick={() => setActiveItem(3)}
            >
              Subscription
              <Link to='/student/dashboard-subscription' />
            </MenuItem> */}
            <MenuItem
              icon={<FaClipboardList />}
              active={activeItem === 4}
              onClick={() => setActiveItem(4)}
            >
              Task List Grid
              <Link to="/student/monthly-document" />
            </MenuItem>
            <MenuItem
              icon={<ImUpload3 />}
              active={activeItem === 6}
              onClick={() => setActiveItem(6)}
            >
              Fieldwork Forms
              <Link to="/student/upload" />
            </MenuItem>
          </Menu>
          {/* activeItem === 0 */}
          {
            <div className="sidebar-accrued-table">
              <table className="agenda-table">
                <tr className="barTableHeader">
                  <th colSpan="2" style={{ fontSize: "12px" }}>
                    Total Accrued:
                  </th>
                </tr>
                <tr>
                  <td>Independent Hours</td>
                  <td className="colSize1">
                    {returnUpperBound(total_hours - total_super) || ""}
                  </td>
                </tr>
                <tr>
                  <td>Unrestricted Hours</td>
                  <td className="colSize1">
                    {returnUpperBound(total_unres) || ""}
                  </td>
                </tr>
                <tr>
                  <td>% Unrestricted</td>
                  <td className="colSize1">
                    {returnUpperBound(total_unres_per) || ""}
                  </td>
                </tr>
                <tr>
                  <td># Supervision Contacts</td>
                  <td className="colSize1">
                    {returnUpperBound(supervision_contacts) || ""}
                  </td>
                </tr>
                <tr>
                  <td>% Supervision</td>
                  <td className="colSize1">{returnUpperBound(total_super_per) || ""}</td>
                </tr>
              </table>
            </div>
          }
          {
            <AccruedTable
              title={`Month to Date:  ${moment()
                .month(today.getMonth())
                .format("MMMM")}`}
              data={values?.accruedData?.weekly || {}}
            />
          }
          {/* {
            activeItem === 2 &&
            <div className='sidebar-accrued-table'>
              <table className='agenda-table'>
                <tr className="barTableHeader">
                  <th colSpan="2" style={{ fontSize: '12px' }}>
                    Total Accrued:
                  </th>
                </tr>
                <tr>
                  <td>Independent Hours</td>
                  <td className="colSize1">{independent_hours || ''}</td>
                </tr>
                <tr>
                  <td>Unrestricted Hours</td>
                  <td className="colSize1">{unrestricted_hours || ''}</td>
                </tr>
                <tr>
                  <td>% Unrestricted</td>
                  <td className="colSize1">{percent_unrestricted?.toFixed(0) || ''}</td>
                </tr>
                <tr>
                  <td># Supervision Contacts</td>
                  <td className="colSize1">{supervision_contacts || ''}</td>
                </tr>
                <tr>
                  <td>% Supervision</td>
                  <td className="colSize1">{percent_supervision?.toFixed(0) || ''}</td>
                </tr>
              </table>
            </div>
          }
          
          {
            activeItem === 4  &&
            <div className='sidebar-accrued-table'>
              <table className='agenda-table'>
                <tr className="barTableHeader">
                  <th colSpan="2" style={{ fontSize: '12px' }}>
                    Total Accrued:
                  </th>
                </tr>
                <tr>
                  <td>Independent Hours</td>
                  <td className="colSize1">{independent_hours || ''}</td>
                </tr>
                <tr>
                  <td>Unrestricted Hours</td>
                  <td className="colSize1">{unrestricted_hours || ''}</td>
                </tr>
                <tr>
                  <td>% Unrestricted</td>
                  <td className="colSize1">{percent_unrestricted?.toFixed(0) || ''}</td>
                </tr>
                <tr>
                  <td># Supervision Contacts</td>
                  <td className="colSize1">{supervision_contacts || ''}</td>
                </tr>
                <tr>
                  <td>% Supervision</td>
                  <td className="colSize1">{percent_supervision?.toFixed(0) || ''}</td>
                </tr>
              </table>
            </div>
          } */}
        </SidebarContent>
      </ProSidebar>
    </div>
  );
}

const AccruedTable = ({ title, data }) => {
  const {
    independent_hours,
    unrestricted_hours,
    percent_unrestricted,
    supervision_contacts,
    percent_supervision,
  } = data;

  const tableData = usetableData();
  const today = new Date();
  let month;

  const [independent, setIndependet] = useState(0);
  const [superPercent, setSuperPercent] = useState(0);
  const [unrest, setUnrest] = useState(0);

  useEffect(() => {
    month = tableData.find((x) => {
      let split = x.date.split("-");
      let date = new Date(split[1], split[0]);

      if (
        date.getFullYear() === today.getFullYear() &&
        date.getMonth() === today.getMonth() + 1
      )
        return x;
    });

    setIndependet(month?.total_hours - month?.supervised);
    setSuperPercent(
      findPercentage(
        Number(month?.supervised).toFixed(3),
        Number(month?.total_hours).toFixed(3)
      ).toFixed(1)
    );
    let check = Number(
      (month?.unrestricted / month?.total_hours) * 100
    ).toFixed(1);
    if (isNaN(check)) check = 0;
    console.log(check);
    setUnrest(check);
  }, [tableData]);

  useDispatchEffect(fetchTable, null, true);

  return (
    <div className="sidebar-accrued-table">
      <table className="agenda-table">
        <tr className="barTableHeader">
          <th colSpan="2">{title}</th>
        </tr>
        <tr>
          <td>Independent Hours</td>
          <td className="colSize1">
            {!isNaN(independent) ? returnUpperBound(independent) : 0}
          </td>
        </tr>
        <tr>
          <td>Unrestricted Hours</td>
          <td className="colSize1">
            {returnUpperBound(unrestricted_hours) || 0}
          </td>
        </tr>
        <tr>
          <td>% Unrestricted</td>
          <td className="colSize1">{returnUpperBound(unrest) || 0}</td>
        </tr>
        <tr>
          <td># Supervision Contacts</td>
          <td className="colSize1">
            {returnUpperBound(supervision_contacts) || 0}
          </td>
        </tr>
        <tr>
          <td>% Supervision</td>
          <td className="colSize1">{returnUpperBound(superPercent) || 0}</td>
        </tr>
      </table>
    </div>
  );
};
