import React, { useEffect, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import {
  setShowSidebar,
  useShowSidebar,
} from "../../../redux/reducers/SidebarReducer";
import "./dashboard.css";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import pavlov from "../../../assets/badges/3.png";
import {
  useChartData,
  useIsLoading,
  usetableData,
} from "../../../redux/reducers/DashboardReducer";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { useDispatchEffect } from "../../../utils/hooks";
import { fetchChartData } from "../../../redux/sagas/dasboard/fetchChartSaga";
import DoughnutChart from "../../../components/DoughnutChart";
import { fetchTable } from "../../../redux/sagas/dasboard/fetchTableSaga";
import moment from "moment";
import { useBadgeValue } from "../../../redux/reducers/ProfileReducer";
import {
  findPercentage,
  findSum,
  concentratedSum,
  nonConcentratedSum,
  IndependentSum,
} from "../../../services/utiltities";
import { useNavigate } from "react-router-dom";
import DoughnutChartConcentrated from "../../../components/DoughnutChartConcentrated";
import { returnUpperBound } from "./../../../utils/textUtil";
import { current } from "@reduxjs/toolkit";
import UploadDocumentModal from "../../../components/UploadDocumentModal";
import { ImUpload3 } from "react-icons/im";
import { RiErrorWarningFill } from "react-icons/ri";
import CSVDownloadBtn from "../../../components/CSVDownloadBtn";
import { fetchFiles } from "../../../redux/sagas/recordFile/fetchRecordFilesSaga";
import { useRecordFiles } from "../../../redux/reducers/RecordFileReducer";
import { useLocation } from "react-router-dom";
import faqIcon from "../../../assets/contact_support.svg";
import { Divider, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import PersonAdd from "@mui/icons-material/PersonAdd";
import DownloadIcon from "@mui/icons-material/Download";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { deleteFile } from "../../../redux/sagas/recordFile/deleteRecordFileSaga";
import swal from "sweetalert";

const customModalStyles = {
  modal: {
    maxWidth: "600px", // Set your desired custom width here
    width: "100%",
    maxHeight: "90vh",
    height: "100%",
    margin: 0,
  },
};

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const showSidebar = useShowSidebar();
  const dispatch = useDispatch();
  useDispatchEffect(fetchFiles, null, true);
  const recordFiles = useRecordFiles();
  const chartData = useChartData();
  let tableData = usetableData();
  const isLoading = useIsLoading();
  const badgeValue = useBadgeValue();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [type, setType] = useState("M-FVF");
  const [initialFormValues, setinitialFormValues] = useState({});
  const [docDate, setDocDate] = useState();
  const [dataDoc, setData] = useState();
  const onOpenModal = () => setOpen(true);
  const onCloseModal = () => setOpen(false);
  const refreshFiles = useDispatchEffect(fetchFiles, null, true);

  const unweightedTotal = {
    total_hours: findSum("total_hours", tableData),
    independent: IndependentSum("supervised", tableData),
    supervised: findSum("supervised", tableData),
    supervision: findSum("supervision", tableData),
    restricted: findSum("restricted", tableData),
    unrestricted: findSum("unrestricted", tableData),
    concentrated: findSum("concentrated_hours", tableData),
    nonConcentrated: findSum("nonconcentrated_hours", tableData),
    group: findSum("group", tableData),
  };

  const { total_hours, restricted_unrestricted, supervision_unsupervised } =
    chartData || {};

  const supervisedHrs = unweightedTotal.supervised || 0;
  const concentratedHrs = unweightedTotal.concentrated * 1.33 || 0;
  const nonConcentratedHrs = unweightedTotal.nonConcentrated;

  const restricted = unweightedTotal?.restricted || 0;
  const unrestricted = unweightedTotal?.unrestricted || 0;

  useDispatchEffect(fetchChartData, null, true);
  const refreshTableData = useDispatchEffect(fetchTable, null, true);

  // for see uploaded files menu
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);

  const [currentItem, setCurrentItem] = useState(null);
  const [currentItem2, setCurrentItem2] = useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMenuOpen2 = Boolean(anchorEl2);
  const handleClick = (event, item) => {
    setAnchorEl(event.currentTarget);
    setCurrentItem(item);
  };

  const handleClick2 = (event, item) => {
    setAnchorEl2(event.currentTarget);
    setCurrentItem2(item);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setCurrentItem(null);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
    setCurrentItem2(null);
  };

  const refreshData = () => {
    refreshTableData();
    refreshFiles();
  };

  const confirmFileDelete = (fileName, id) => {
    swal({
      title: "Delete this file?",
      text: fileName,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        handleClose();
        dispatch(
          deleteFile({
            id,
            refreshFiles: refreshData,
          })
        );
      }
    });
  };

  const handleRemove = (data, paramType) => {
    const currentType = paramType || type;
    const fileName =
      currentType === "M-FVF" ? data?.monthly_form.title : data?.title;
    const id = currentType === "M-FVF" ? data?.monthly_form.id : data?.id;

    confirmFileDelete(fileName, id);
  };

  const addNullData = (data) => {
    const dates = data.map((obj) => new Date(obj.year, obj.month - 1));
    const minDate = new Date(Math.min.apply(null, dates));
    const maxDate = new Date(Math.max.apply(null, dates));

    const months = [];
    let currentDate = new Date(minDate);
    while (currentDate <= maxDate) {
      months.push({
        month: currentDate.getMonth() + 1,
        year: currentDate.getFullYear(),
      });
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    const result = months.map((month) => {
      const matchingData = data.find(
        (obj) => obj.month === month.month && obj.year === month.year
      );
      if (matchingData) {
        return matchingData;
      } else {
        return {
          month: month?.month,
          year: month?.year,
          date: `${month?.month}-${month?.year}`,
          total_hours: 0,
          independent: 0,
          supervised: 0,
          "supervised%": 0,
          group: 0,
          "group%": 0,
          restricted: 0,
          unrestricted: 0,
          "unrestricted%": 0,
          type: ["-"],
        };
      }
    });

    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    if (months[months.length - 1].month === currentMonth) {
      result.push({
        month: currentMonth + 1,
        year: currentYear,
        date: `${currentMonth + 1}-${currentYear}`,
        total_hours: 0,
        independent: 0,
        supervised: 0,
        "supervised%": 0,
        group: 0,
        "group%": 0,
        restricted: 0,
        unrestricted: 0,
        "unrestricted%": 0,
        type: ["-"],
      });
    }

    result.sort((a, b) => {
      const yearDiff = b.year - a.year;
      if (yearDiff !== 0) {
        return yearDiff;
      }
      return b.month - a.month;
    });

    return result;
  };

  const ToMonthlyDoc = (date) => {
    navigate("/student/monthly-document", {
      replace: true,
      state: { data: { date } },
    });
  };

  const showColumnFile = (item) => {
    const date = new Date();
    const currentDate = new Date(date.getFullYear(), date.getMonth() - 1);
    const compareDate = new Date(item.year, item.month - 1);

    function dateFormatter(month, year) {
      const formattedMonth = month.toString().padStart(2, "0");
      return `${year}-${formattedMonth}-01`;
    }
    const formatDate = dateFormatter(item.month, item.year);

    return (
      <td
        style={{
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            whiteSpace: "nowrap",
            alignItems: "center",
          }}
        >
          {item?.monthly_form?.length ? (
            <Tooltip title="See Uploaded Files">
              <div
                style={{
                  cursor: "pointer",
                  width: "100%",
                  textDecoration: "underline",
                  textUnderlineOffset: 3,
                }}
                onClick={(e) => handleClick(e, item)}
              >
                {item.monthly_form.length}
                {item.monthly_form?.length === 1 ? " form" : " forms"} uploaded
              </div>
            </Tooltip>
          ) : (
            <div style={{ cursor: "pointer", width: "100%" }}>
              No form uploaded yet
            </div>
          )}
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={isMenuOpen}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
              },
            }}
            transformOrigin={{ horizontal: "center", vertical: "top" }}
            anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
          >
            {currentItem?.monthly_form.map((eachItem, index, array) => (
              <>
                <MenuItem
                  onClick={handleClose}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{eachItem.title}</div>
                  <div
                    style={{
                      marginLeft: 15,
                      display: "flex",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <Tooltip title="Download">
                      <ListItemIcon
                        className="menuIcon"
                        onClick={(e) => {
                          window.open(`${eachItem.document}`, "_blank");
                        }}
                      >
                        <DownloadIcon fontSize="11px" />
                      </ListItemIcon>
                    </Tooltip>
                    <Tooltip title="Edit">
                      <ListItemIcon
                        className="menuIcon"
                        style={{ minWidth: 22 }}
                        onClick={(e) => {
                          handleShowModal(true, "M-FVF", formatDate, {
                            monthly_form: eachItem,
                          });
                        }}
                      >
                        <EditIcon fontSize="11px" />
                      </ListItemIcon>
                    </Tooltip>
                    <Tooltip title="Delete">
                      <ListItemIcon
                        className="menuIcon"
                        style={{ minWidth: 22 }}
                        onClick={(e) => {
                          handleRemove(
                            {
                              monthly_form: eachItem,
                            },
                            "M-FVF"
                          );
                        }}
                      >
                        <DeleteOutlineIcon fontSize="11px" />
                      </ListItemIcon>
                    </Tooltip>
                  </div>
                </MenuItem>
                {index !== array.length - 1 && <Divider />}
              </>
            ))}
          </Menu>

          <Tooltip title="Upload Form">
            <button
              onClick={() => handleShowModal(false, "M-FVF", formatDate, item)}
              className="menuIcon"
              style={{
                marginLeft: 15,
                minWidth: "unset",
              }}
            >
              <ImUpload3 style={{ color: "#444444" }} />
            </button>
          </Tooltip>
        </div>

        {/* {item?.monthly_form?.length > 0 && (
          <>
            <div style={{ fontWeight: "bold", marginTop: 5 }}>
              Previously Uploaded
            </div>
          </>
        )} */}

        {/* {item?.monthly_form?.map((eachItem, index) => (
          <Tooltip title="Click to view, edit or delete this document." placement="top" key={index}>
            <div
              style={{
                textAlign: "center",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                handleShowModal(true, "M-FVF", formatDate, {
                  monthly_form: eachItem,
                })
              }
            >
              {eachItem?.title}
            </div>
          </Tooltip>
        ))} */}
      </td>
    );
  };

  const handleShowModal = (edit, typeInput, date, item) => {
    if (typeInput === "M-FVF") {
      if (edit) {
        setinitialFormValues({
          filled: true,
          downloaded: true,
          signed: true,
          fileName: item?.monthly_form?.title,
          supervisor: JSON.stringify(item?.monthly_form?.trainee_supervisor),
          notes: item?.monthly_form?.notes,
          image: null,
        });
      } else {
        setinitialFormValues({
          filled: false,
          downloaded: false,
          signed: false,
          fileName: "",
          supervisor: 0,
          notes: "",
          image: null,
        });
      }
      setType(typeInput);
      setDocDate(date);
      setData(item);
    } else {
      if (edit) {
        setinitialFormValues({
          filled: true,
          downloaded: true,
          signed: true,
          fileName: item?.title,
          supervisor: JSON.stringify(item?.trainee_supervisor),
          notes: item?.notes,
          description: item?.description,
          image: null,
        });
        setData(item);
      } else {
        setinitialFormValues({
          filled: false,
          downloaded: false,
          signed: false,
          fileName: "",
          supervisor: 0,
          notes: "",
          description: "",
          image: null,
        });
      }
      setType(typeInput);
      setDocDate(date); //no date
    }
    if (edit) {
      setIsEdit(true);
      setOpen(true);
    } else {
      setIsEdit(false);
      setOpen(true);
    }
  };

  useEffect(() => {
    // Call the function to add missing months
    if (tableData.length > 0) {
      tableData = addNullData(tableData);
    }
  }, [tableData]);

  useEffect(() => {
    if (location?.state !== null) {
      if (location.state.data.type === "M-FVF") {
        setinitialFormValues({
          filled: false,
          downloaded: false,
          signed: false,
          fileName: "",
          supervisor: 0,
          notes: "",
          image: null,
        });
      } else {
        setinitialFormValues({
          filled: false,
          downloaded: false,
          signed: false,
          fileName: "",
          supervisor: 0,
          notes: "",
          description: "",
          image: null,
        });
      }
      setType(location.state.data.type);
      setOpen(true);
      setIsEdit(false);
    }
  }, [location]);

  return (
    <div className="section">
      {
        <Modal
          open={open}
          onClose={onCloseModal}
          center
          styles={customModalStyles}
        >
          <UploadDocumentModal
            initialValues={initialFormValues}
            type={type}
            isEdit={isEdit}
            onClose={onCloseModal}
            date={docDate}
            data={dataDoc}
            onRefresh={refreshTableData}
          />
        </Modal>
      }
      <div className="columns">
        <div className="column"></div>
      </div>
      <div className="columns">
        <div className="column">
          <button
            className="Toggle_btn"
            onClick={() => dispatch(setShowSidebar(!showSidebar))}
          >
            {showSidebar ? <GiHamburgerMenu /> : <GiHamburgerMenu />}
          </button>
          <div className="captionWrap">
            <div className="dashHead">
              <p className="headingMain">Dashboard </p>
              <img src={pavlov} />
            </div>

            <p className="faq-wrap">
              <a
                className="faq-container"
                onClick={() => navigate(`/student/faq`)}
              >
                <span>FAQ</span>
                <img src={faqIcon} alt="Faq" />
              </a>
            </p>
          </div>

          {isLoading ? (
            <LoadingSpinner style={{ width: "100%" }} />
          ) : (
            <div className="columns mt30">
              <div className="column row-space-evenly">
                <div className="cards bottom-text-div">
                  <p className="card-text"> TOTAL HOURS COMPLETED </p>
                  <div className="doughnut-div-main mt10">
                    <div className="doughnut-div">
                      <DoughnutChartConcentrated
                        label="Supervision/unsupervised"
                        data={[nonConcentratedHrs + concentratedHrs]}
                        nonConcentrated={nonConcentratedHrs}
                        concentrated={concentratedHrs}
                        backgroundColor={["#75D6D1", "#ddd"]}
                        height={100}
                        width={100}
                      />
                    </div>
                  </div>
                  <div className="bottom">
                    <p className="card-text"> Supervised: {supervisedHrs}</p>
                    <p>
                      Concentrated:{" "}
                      {returnUpperBound(concentratedHrs.toFixed(2), 0.25)}
                    </p>
                  </div>
                </div>
                <div
                  className="cards bottom-text-div"
                  style={{ height: "95%" }}
                >
                  <p className="card-text">
                    {" "}
                    RESTRICTED VS UNRESTRICTED HOURS{" "}
                  </p>

                  <div className="doughnut-div-main mt10">
                    <div className="doughnut-div">
                      <DoughnutChart
                        label="Restricted/unrestricted"
                        labels={["restricted", "unrestricted"]}
                        data={[restricted, unrestricted]}
                        backgroundColor={["#75D6D1", "#F9E18A"]}
                        height={100}
                        width={100}
                      />
                      <div className="bottom">
                        <p className="card-text" style={{ color: "white" }}>
                          Restricted
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <p className="headingMain mt30">Monthly Details</p>

          <p className="mt30 comingSoon">
            <em>
              <strong>Coming soon!</strong>
            </em>{" "}
            Bulk form download.
          </p>

          <div className="mt30 agendaTable">
            <table>
              <tr>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Month
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Total Hours
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  M-FVF Form
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Independent
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Supervised
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Group Supervision %
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Restricted
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Unrestricted
                </th>
                <th
                  className="agendaTableHeader"
                  style={{ border: "1px solid #B7B7B7" }}
                >
                  Type
                </th>
              </tr>
              <tbody>
                {isLoading ? (
                  <LoadingSpinner style={{ width: "100%" }} />
                ) : (
                  tableData.map((item, key) => {
                    return (
                      <tr key={key} className="tablevalue">
                        <td
                          onClick={() =>
                            ToMonthlyDoc(getDate(item.month, item.year))
                          }
                          className="hoverBtn"
                          style={{ textAlign: "center" }}
                        >
                          {getDate(item.month, item.year)}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(Number(item["total_hours"]))}
                        </td>
                        {showColumnFile(item)}
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(
                            Number(item["total_hours"]) -
                              Number(item["supervised"])
                          )}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(Number(item["supervised"]))}
                          &nbsp;|&nbsp;
                          {returnUpperBound(
                            findPercentage(
                              Number(item["supervised"]).toFixed(3),
                              Number(item["total_hours"]).toFixed(3)
                            ).toFixed(1)
                          ) + "%"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(Number(item["group%"]).toFixed(1)) +
                            "%"}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(Number(item["restricted"]))}
                        </td>
                        <td style={{ textAlign: "center" }}>
                          {returnUpperBound(
                            Number(item["unrestricted"]).toFixed(3)
                          )}
                          &nbsp;|&nbsp;
                          {returnUpperBound(
                            Number(item["unrestricted%"]).toFixed(1)
                          ) + "%"}
                        </td>
                        <td
                          style={{ textAlign: "center", whiteSpace: "nowrap" }}
                        >
                          {item.type?.length ? item.type.join(", ") : ""}
                        </td>
                      </tr>
                    );
                  })
                )}
                {!isLoading && tableData.length > 0 && (
                  <tr className="totalvalue">
                    <td style={{ textAlign: "center", fontWeight: "bold" }}>
                      Unweighted Total
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(Number(unweightedTotal["total_hours"]))}
                    </td>
                    <td></td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(Number(unweightedTotal["independent"]))}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(Number(unweightedTotal["supervised"]))}
                      &nbsp;|&nbsp;
                      {returnUpperBound(
                        findPercentage(
                          unweightedTotal["supervised"],
                          unweightedTotal["total_hours"]
                        ).toFixed(1)
                      )}
                      %
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(
                        findPercentage(
                          unweightedTotal["group"],
                          unweightedTotal["total_hours"]
                        ).toFixed(1)
                      )}
                      %
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(Number(unweightedTotal["restricted"]))}
                    </td>
                    <td style={{ textAlign: "center" }}>
                      {returnUpperBound(
                        Number(unweightedTotal["unrestricted"])
                      )}
                      &nbsp;|&nbsp;
                      {returnUpperBound(
                        findPercentage(
                          unweightedTotal["unrestricted"],
                          unweightedTotal["restricted"] +
                            unweightedTotal["unrestricted"]
                        ).toFixed(1)
                      )}
                      %
                    </td>
                    <td style={{ textAlign: "center" }}>{""}</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="columns mt30">
            <div className="column is-6 is-12-mobile">
              <div className="mb30 card-dashboard Final-Field-container">
                <h2>
                  <b>Final Fieldwork Verification Forms</b>
                </h2>
                <p>
                  We highly recommend that the F-FVF be signed at the end of a
                  specific fieldwork experience. These forms will be submitted
                  in the certification process.
                </p>
                <a
                  style={{ color: "#d37498", textDecoration: "underline" }}
                  onClick={() => {
                    navigate("/student/upload", {
                      replace: true,
                    });
                  }}
                >
                  Download the F-FVF form
                </a>
                {recordFiles.length > 0 ? (
                  <table>
                    <tr>
                      <th
                        className="agendaTableHeader mt30"
                        style={{ border: "1px solid #B7B7B7" }}
                      >
                        Description
                      </th>
                      <th
                        className="agendaTableHeader"
                        style={{ border: "1px solid #B7B7B7" }}
                      >
                        Supervisor
                      </th>
                      <th
                        className="agendaTableHeader"
                        style={{ border: "1px solid #B7B7B7" }}
                      >
                        F-FVF Form
                      </th>
                    </tr>
                    <tbody>
                      {recordFiles
                        .filter((d) => d.form_type === "Final")
                        .map((item, key) => {
                          return (
                            <tr key={key}>
                              <td>{item.description}</td>
                              <td>
                                {item.supervisor === null
                                  ? item.trainee_supervisor?.name +
                                    " " +
                                    item.trainee_supervisor?.lastname
                                  : item.supervisor?.name}
                              </td>

                              <td>
                                <Tooltip
                                  title={item.title}
                                  placement="bottom-start"
                                >
                                  <div
                                    style={{
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      maxWidth: "200px"
                                    }}
                                    onClick={(e) => {
                                      handleClick2(e, item);
                                    }}
                                  >
                                    {item.title}
                                  </div>
                                </Tooltip>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                ) : (
                  <></>
                )}

                <Menu
                  anchorEl={anchorEl2}
                  id="account-menu"
                  open={isMenuOpen2}
                  onClose={handleClose2}
                  onClick={handleClose2}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                      mt: 1.5,
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: "center", vertical: "top" }}
                  anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                >
                  <MenuItem
                    onClick={(e) => {
                      handleShowModal(
                        true,
                        "F-FVF",
                        currentItem2?.date,
                        currentItem2
                      );
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Edit
                  </MenuItem>
                  <MenuItem
                    onClick={(e) => {
                      handleRemove(currentItem2, "F-FVF");
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Delete
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      window.open(`${currentItem2.document}`, "_blank");
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    Download
                  </MenuItem>
                </Menu>

                <button
                  onClick={() => handleShowModal(false, "F-FVF", "no date", {})}
                >
                  Upload F-FVF
                </button>
              </div>
            </div>
            <div className="column is-6 is-12-mobile">
              <div className="card-dashboard task-data-container">
                <h2>
                  <b>Task Data</b>
                </h2>
                <p>Filter by Task list items documented.</p>
                <CSVDownloadBtn />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getDate = (month, year) => {
  const date = new Date();
  date.setFullYear(year, month - 1);
  return moment(date).format("MMMM YYYY");
};
export default Dashboard;
